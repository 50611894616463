/*------------------------------------------------------------------ 
Project: InbiSense Website 
Version: 1.0
Primary use: Corporate, Business and Portfolio
 -------------------------------------------------------------------*/

/* Index of CSS
 ================================================== 
 1. Global styles 
*/
 
/*---------------------------------------------------- 
 1. Global styles
------------------------------------------------------*/
@font-face {
    font-family: 'chevin_bold_regular';
    src: local('chevin_bold_regular'), url(../fonts/chevin_bold_regular.woff) format('woff');
}

.inbi_top {
    font-size: 60px;
    padding: 40px;
    background-color: rgb(0, 60, 100);
    font-family: 'chevin_bold_regular', sans-serif;
}

.navigation {
    background-color: rgb(0, 60, 100);
    z-index: 999;
    position: relative;
    padding-top: 20px;
}

.inbi_header .inbi_large {
    color: white;
    font-family: 'chevin_bold_regular', sans-serif;
    font-size: 20px;
    padding-top: 40px;
    text-align: center;
}

.inbi_header .inbi_large_highlight {
    color: rgb(190, 215, 30);
    font-family: 'chevin_bold_regular', sans-serif;
    font-size: 20px;
}

.vertical-center {
    position: absolute;
    top: 35%;
    left: 65%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.inbi_img_size_large {
    max-width: 50%;
}

.inbi_img_size_xlarge {
    max-width: 70%;
}

.inbi_img_size_xxlarge {
    max-width: 90%;
}

.inbi_img_size_medium {
    max-width: 25%;
}

.inbi_img_size_small {
    max-width: 15%;
    position: relative;
    top: -150px;
    right: -800px;
}

.inbi_background_highlight {
    background-color: rgb(190, 215, 30);
}

.inbi_navigation {
    background-color: rgb(190, 215, 30);
    z-index: 999;
    position: relative;
    font-family: 'chevin_regular', sans-serif;
    font-size: 20px;
}

#navigation ul ul li:hover > a, #navigation ul ul li a:hover {
    color: rgb(190, 215, 30);
    background: rgb(0, 60, 100);
}

.inbi_banner {
    height: 145px;
    background-size: 100%;
    color: white;
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 200px;
}

.inbi_banner p {
    font-family: 'chevin_bold_regular', sans-serif;
    font-size: 40px;
    position: relative;
    text-align: right;
    padding-top: 50px;
    padding-right: 20px;
    line-height: 80%;
    margin: 0px;
}

.inbi_title {
    background: rgb(190, 215, 30);
    color: rgb(0, 60, 100);
    padding: 1px;
    font-family: 'chevin_bold_regular', sans-serif;
    font-size: 30px;
    padding-left: 30px;
    margin-bottom: 10px;
}

.about_list p {
    font-size: 18px;
    font-family: 'chevin_regular', sans-serif;
}

.about-section {
    background-color: #ffffff;
    padding-bottom: 0px;
    padding-top: 0px;
}

.service_section {
    background-color: #ffffff;
    padding-bottom: 0px;
    padding-top: 0px;  
}

.contact-section {
    background-color: #ffffff;
    padding-bottom: 0px;
    padding-top: 0px; 
}

.inbi_contact_title {
    background: rgb(190, 215, 30);
    color: rgb(0, 60, 100);
    padding: 1px;
    font-family: 'chevin_bold_regular', sans-serif;
    font-size: 30px;
    padding-left: 30px;
    margin-bottom: 0px;
}

.inbi-contact {
    font-family: 'chevin_regular', sans-serif;
    font-size: 22px;
    padding-left: 50px;
    padding-top: 6%;
    height: 20%;
    color: white;
    display:block;

    padding-right: unset;
    margin-right: unset;
}

.inbi-list ul {
  margin: 0.75em 0;
  padding: 0 1em;
  list-style: none;
}

.inbi-list ul li::before {
    content: "";
    border-color: transparent rgb(255, 252, 252);
    border-style: solid;
    border-width: 0.35em 0 0.35em 0.45em;
    display: block;
    height: 0;
    width: 0;
    left: -1em;
    top: 0.9em;
    position: relative;
}

.inbi-list a {
    color: white;
}

.contact_info {
    background-color: rgb(0, 60, 100);
}

.inner-contact {
    margin-right: unset;
}

.inbi_curve {
    background-color: rgb(190, 215, 30);
    margin-top: -10px;
}

.inbi_navbar_navitem {
    margin-bottom: -25px;
}

.service_para p {
    font-size: 18px;
    font-family: 'chevin_regular', sans-serif;
}

.service_para {
    min-height: 350px;
}

.service_img {
    min-height: 300px;
}

.single_service_title {
    font-size: 18px;
    font-family: 'chevin_regular', sans-serif;
}

.single_service_category {
    font-family: 'chevin_regular', sans-serif; 
}

#navigation > ul > li:hover > a {
    color: white;
}

#navigation ul ul li a {
    width: 280px;
}

.inbi_product_image_parent {
    position: relative;
    top: 0;
    left: 0;
}

.inbi_product_image_parent1 {
    position: relative;
    top: 0;
    left: 0;
}

.image1 {
    position: relative;
    top: 20px;
    left: 180px;
}

.image1single {
    position: relative;
    top: 20px;
    left: 180px;
}

.image2 {
    position: absolute;
    top: 40px;
    left: 120px;
}

.image2single {
    position: absolute;
    top: 40px;
    left: 120px;
}

.inbi_img_size_large2a {
    max-width: 50%;
    margin-left: -120px;
}

.inbi_img_size_large1 {
    max-width: 70%;
}

.inbi_img_size_large1a {
    max-width: 70%;
    margin-left: -120px;
}

.inbi_img_size_medium1 {
    max-width: 50%;
}

.inbi_img_size_medium2 {
    max-width: 30%;
    margin-left: 210px;
}

.inbi_img_size_medium3 {
    max-width: 30%;
    margin-left: 130px;
    margin-top: -210px;
}

.inbi_img_size_xlarge1 {
    max-width: 70%;
    margin-left: -180px;
    margin-bottom: 40px;
}

.header-btn-wrapper i {
    color: rgb(190, 215, 30);
    font-size: 32px;
    position: relative;
    top: 16px;
    left: 2px;
}

.subfooter {
    float: left;
    width: 100%;
    padding: 20px 15px;
    border-top: 0px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer-section {
    float: left;
    width: 100%;
    color: #fff;
    background: rgb(0, 60, 100);
    padding-top: 0px;
}

.image1 {
    position: relative;
    top: 20px;
    left: 180px;
}

.image2 {
    position: absolute;
    top: 40px;
    left: 120px;
}

/* 
=> 1200 and <= 1500 
*/
@media only screen and (min-width: 1400px) {

    .service_img {
        min-height: 400px;
    }
    
    .service_para {
        min-height: 350px;
    }
}

/* 
=> 1199 
*/
@media only screen and (min-width: 1199px) and (max-width: 1399px) {

    .service_img {
        min-height: 350px;
    }
    
    .service_para {
        min-height: 350px;
    }
}

/*
=> 992 and <= 1199
/* Tablet Landscape 
 =======================================================================================*/
 @media only screen and (min-width: 992px) and (max-width: 1199px) {

    .service_img {
        min-height: 310px;
    }

    .service_para {
        min-height: 350px;
        min-height: 350px;
    }
 }

/* 
<= 991
=======================================================================================*/ 
@media(max-width:991px) {
    .service_para {
        min-height: 350px;
    }
 }

 /*
 Tablet Portrait 
 => 768 and <= 991
=======================================================================================*/ 
@media (min-width: 768px) and (max-width: 991px) {

    .service_img {
        min-height: 340px;
    }

    .image1 {
        left: 210px;
    }
    
    .image2 {
        left: 150px;
    }

    .image1single {
        left: 115px;
        position: relative;
        top: 20px;
    }
    
    .image2single {
        left: 70px;
        position: absolute;
        top: 40px;
    }

    .inbi_img_size_medium2 {
        margin-left: 170px;
    }
 }

 /* Phone 
 <= 767
 =======================================================================================*/
 @media only screen and (max-width: 767px) {

    .checkmobile {
        display:none;
    }
}

 /* Mobile Vertical Layout: 488px 
 => 488 and <= 767
 ===============================================================================*/
 @media only screen and (min-width: 488px) and (max-width: 767px) {

 }

 .inbi_footer_top {
    margin-top:20px;
 }

 .w3-tooltip .w3-text {
    display: none
}

.w3-tooltip:hover .w3-text {
    display: inline-block
}

.inbi-row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
}

.service_list:hover {
    opacity: unset;
}

.service_list:hover .service_img::after {
    opacity: 0;
}